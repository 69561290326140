
import { PopoutsModule } from "@/store/modules/popouts";
import CustomButton from "@/components/CustomButton.vue";
import InformationTemplate from "@/components/InformationTemplate.vue";
import { googleDriveLinkURL } from "@/services/authService";
import { MeModule } from "@/store/modules/me";

import { defineComponent } from "vue";
export default defineComponent({
  name: "ProfilePopout",
  components: { CustomButton, InformationTemplate },
  props: {
    identity: {
      type: String,
      required: true,
    },
  },
  computed: {
    linked(): any {
      return MeModule.user.googleDriveLinked;
    },
  },
  watch: {
    linked: {
      handler: "onLinkedChange",
    },
  },
  methods: {
    close() {
      PopoutsModule.ClosePopout(this.identity);
    },
    link() {
      googleDriveLinkURL().then(({ url }) => {
        window.open(url, "_blank");
      });
    },
    buttonClicked() {
      this.close();
    },
    onLinkedChange(linked: boolean) {
      if (linked) {
        this.close();
      }
    },
  },
});
